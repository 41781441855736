import { Component, ViewChild, AfterViewInit } from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import { MOCK_DATA } from './mock.data';
import { DataService } from '../data.service';
import { ReferralService } from '../referral.service';
import { MonthlyEmployeeBenefit, Benefit } from '../models';

export interface UserData {
  name: Option;
  project: string;
  referred: string;
  period: string;
  amount: number;
  currency: string;
}

interface Option {
  name: string;
  id: number;
}

@Component({
  selector: 'app-talent',
  templateUrl: './talent.component.html',
  styleUrls: ['./talent.component.scss']
})

export class TalentComponent implements AfterViewInit {
  month: string = "";

  dataSource: MatTableDataSource<UserData> = new MatTableDataSource<UserData>();
  @ViewChild(MatSort) sort: MatSort = <MatSort>{};
  @ViewChild(MatPaginator) paginator: MatPaginator = <MatPaginator>{};    
  displayedColumns: string[] = ['name', 'project', 'referred', 'period', 'amount', 'currency'];

  constructor(private referralService: ReferralService, private dataService: DataService) {
    this.month = this.dataService.getMonth();
    this.getBenefits();

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getBenefits() {
    console.log('Fetching monthly benefits for:', this.month);
    var data: UserData[] = [];
    this.referralService.getMonthlyReferrals(this.month)
      .subscribe({
        next: resp => {
          (resp.employees as MonthlyEmployeeBenefit[]).forEach(employee => {
            let emp: UserData = {
              name: { name: employee.employee.first_name + ' ' + employee.employee.last_name, id: employee.employee.id },
              project: employee.employee.project,
              currency: '',
              period: '',
              amount: 0,
              referred: ''
            }
            if (employee.benefits) {
                (employee.benefits as Benefit[]).forEach(b => {
                  emp.amount = parseFloat(b.amount);
                  emp.currency = b.currency;
                  emp.referred = b.subject;
                  emp.period = b.start;
                  emp.project = b.company;
                  emp.referred = b.referred.first_name + ' ' + b.referred.last_name;
                  data.push(emp);
              });
            }
          });
          this.dataSource = new MatTableDataSource(data);

        }, 
        error: error => {
          console.error('Error fetching monthly benefits:', error);
        }
      });
  }
}
