<!-- table.component.html -->
<table mat-table #table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.name.name}} </mat-cell>
    </ng-container>
  
    <!-- Project Column -->
    <ng-container matColumnDef="project">
      <mat-header-cell *matHeaderCellDef> Project </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.project}} </mat-cell>
    </ng-container>
  
    <!-- Currency Column -->
    <ng-container matColumnDef="referred">
      <mat-header-cell *matHeaderCellDef> Referred Contractor </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.referred}} </mat-cell>
    </ng-container>
  
    <!-- Hourly Rate Column -->
    <ng-container matColumnDef="period">
      <mat-header-cell *matHeaderCellDef> Period </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.period}} </mat-cell>
    </ng-container>
  
    <!-- Benefit Amount Column -->
    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.amount}} </mat-cell>
    </ng-container>
  
    <!-- Education Bonus Column -->
    <ng-container matColumnDef="educationBonus">
      <mat-header-cell *matHeaderCellDef> Education Bonus </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.educationBonus}} </mat-cell>
    </ng-container>
  
    <!-- Other Bonus Column -->
    <ng-container matColumnDef="currency">
      <mat-header-cell *matHeaderCellDef> Currency </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.currency}} </mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </table>
  
    <mat-paginator [pageSizeOptions]="[ 10, 15, 25, 100]"></mat-paginator>
    <button mat-fab extended color="primary">
        <mat-icon>done_outline</mat-icon>
        Accept
      </button>