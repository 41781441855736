<!-- table.component.html -->
<table mat-table #table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.name.name}} </mat-cell>
  </ng-container>

  <!-- Project Column -->
  <ng-container matColumnDef="project">
    <mat-header-cell *matHeaderCellDef> Project </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.project}} </mat-cell>
  </ng-container>

  <!-- Currency Column -->
  <ng-container matColumnDef="currency">
    <mat-header-cell *matHeaderCellDef> Currency </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.currency}} </mat-cell>
  </ng-container>

  <!-- Hourly Rate Column -->
  <ng-container matColumnDef="hourlyRate">
    <mat-header-cell *matHeaderCellDef> Hourly Rate </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.hourlyRate}} </mat-cell>
  </ng-container>

  <!-- Benefit Amount Column -->
  <ng-container matColumnDef="benefitAmount">
    <mat-header-cell *matHeaderCellDef> Benefit Amount </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <input class="table-input" matInput (change)="row.edited = true" [(ngModel)]="row.benefitAmount" type="number">
    </mat-cell>
  </ng-container>

  <!-- Education Bonus Column -->
  <ng-container matColumnDef="educationBonus">
    <mat-header-cell *matHeaderCellDef> Education Bonus </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <input class="table-input" matInput (change)="row.edited = true" [(ngModel)]="row.educationBonus" type="number">
    </mat-cell>
  </ng-container>

  <!-- Other Bonus Column -->
  <ng-container matColumnDef="otherBonus">
    <mat-header-cell *matHeaderCellDef> Other Bonus </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <input class="table-input" matInput (change)="row.edited = true" [(ngModel)]="row.otherBonus" type="number">
    </mat-cell>
  </ng-container>

  <!-- Save Column -->
  <ng-container matColumnDef="save">
    <mat-header-cell *matHeaderCellDef> Save </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button mat-button *ngIf="row.edited" (click)="saveBenefits(row)"><mat-icon>save</mat-icon></button>
    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</table>

<mat-paginator [pageSizeOptions]="[ 10, 15, 25, 100]"></mat-paginator>
<button mat-fab extended color="primary">
  <mat-icon>done_outline</mat-icon>
  Accept
</button>
