<div *ngIf="dataSource && dataSource.invoice && dataSource.summary">
  <h2>Billing Summary</h2>
  <p><strong>Status:</strong> {{dataSource.status}}</p>
  <p><strong>Currency:</strong> {{dataSource.invoice.currency}}</p>
  <p><strong>Total payment with benefits:</strong> {{dataSource.invoice.total}}</p>
  <p><strong>Total payment without benefits:</strong> {{dataSource.summary.total_sum}}</p>
  <p><strong>Total hours:</strong> {{dataSource.summary.total_hours}}</p>
  <p><strong>Rate:</strong> {{dataSource.summary.rate}}</p>
  <p><strong>Payments per:</strong> {{dataSource.summary.payments_per}}</p>
  <p><strong>Date:</strong> {{dataSource.invoice.date}}</p>
</div>
