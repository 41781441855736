import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { KeycloakService } from './keycloak.service';
import { MonthlyBenefits } from './models';

export interface Pagination {
  page: number;
  pages: number;
  count: number;
  items: number;
}

export interface MonthlyReferralsResponse {
  employees: MonthlyBenefits[];
  pagination: Pagination;
}

export interface CreateReferralRequest {
  type: string;
  amount: string;
  currency: string;
  company: string;
  month: string;
}

export interface UpdateReferralRequest {
  amount: string;
  currency: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReferralService {
  private apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient, private keycloakService: KeycloakService) { }

  getMonthlyReferrals(month: string): Observable<MonthlyBenefits> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());

    const params = { month }; // Query parameters object

    return this.http.get<MonthlyBenefits>(`${this.apiUrl}/referral/benefits`, { headers, params });
  }

  createReferral(employeeID: number, data: CreateReferralRequest): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());

    return this.http.post<any>(`${this.apiUrl}/${employeeID}/referrals/`, data, { headers });
  }

  updateReferral(employeeID: number, referralID: string, data: UpdateReferralRequest): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());

    return this.http.put<any>(`${this.apiUrl}/${employeeID}/referrals/${referralID}`, data, { headers });
  }

  deleteReferral(employeeID: number, referralID: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());

    return this.http.delete<any>(`${this.apiUrl}/${employeeID}/referrals/${referralID}`, { headers });
  }
}
