import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { catchError } from 'rxjs/operators';
import { KeycloakService } from './keycloak.service';
import {MonthlyBenefits, UserData} from './models';

export interface Benefit {
  id: string; // Assuming ID is a string
  type: string; // Assuming Type is a string
  amount: string;
  currency: string;
  company: string;
  month: string; // Assuming you want to represent month as string in TypeScript
}

export interface Pagination {
  page: number;
  pages: number;
  count: number;
  items: number;
}

@Injectable({
  providedIn: 'root'
})
export class HrService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private keycloakService: KeycloakService) { }

  getMonthlyBenefits(month: string): Observable<MonthlyBenefits> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());

    const params = { month }; // Query parameters object

    return this.http.get<MonthlyBenefits>(`${this.apiUrl}/hr/benefits`, { headers, params });
  }

  saveMonthlyBenefits(user: UserData): Observable<any> {
    let employeeID = user.name.id;
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.keycloakService.getToken());

    return this.http.post<any>(`${this.apiUrl}/hr/employees/${employeeID}/benefits`, user, { headers });
  }
}
