import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, OnDestroy } from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import { UsersService } from '../users.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ItsService } from '../its.service';
import { TopBarComponent } from '../top-bar/top-bar.component';
import { Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { MonthlyEmployeeBenefit, Benefit } from '../models';


export interface UserData {
  name: Option;
  project: string;
  subject: string;
  amount: number;
  currency: string;
}

interface Option {
  name: string;
  id: number;
}

export const MOCK_DATA = [
  { name: {name:'John Doe', id: 1}, project: 'Project A', currency: 'USD', amount: 30, subject: "Nvidia Geforce RTX4090" }
]

@Component({
  selector: 'app-its',
  templateUrl: './its.component.html',
  styleUrls: ['./its.component.scss'],
})

export class ItsComponent implements OnInit, AfterViewInit, OnDestroy {

  userData: UserData = <UserData>{};
  dataSubscription: Subscription | undefined;
  month: string = "";

  currencies: string[] = ['USD', 'EUR', 'GBP', 'PLN'];
  dataSource: MatTableDataSource<UserData> = new MatTableDataSource<UserData>();
  @ViewChild(MatSort) sort: MatSort = <MatSort>{};
  @ViewChild(MatPaginator) paginator: MatPaginator = <MatPaginator>{};  
  displayedColumns: string[] = ['name', 'project', 'subject', 'amount', 'currency'];

  constructor(private usersService: UsersService, private itsService: ItsService, private dataService: DataService) {
    this.month = this.dataService.getMonth();
    this.getBenefits();
    this.filteredOptions = this.options.slice();
  }

    @ViewChild('input') input: ElementRef<HTMLInputElement> | undefined;
  myControl = new FormControl('');
  options: Option[] = [];
  filteredOptions: Option[];

  filter(): void {
    if (this.input) {
      const filterValue = this.input.nativeElement.value.toLowerCase();
      this.filteredOptions = this.options.filter(o => o.name.toLowerCase().includes(filterValue));
    }
  }

  ngOnInit(): void {
    this.usersService.getUsers().subscribe(users => {
      users.employees.forEach(user => {   
        this.options.push({name: user.first_name + ' ' + user.last_name, id: user.id});
      });
    });
    this.dataSubscription = this.dataService.dataObservable.subscribe(() => {
      console.log('Data changed', this.dataService.getMonth());
      this.month = this.dataService.getMonth();
      this.getBenefits();
    });
  }
  
  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  displayFn(option: any): string {
    return option && option.name ? option.name : '';
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onSubmit() {
    console.log(this.userData);
    const data = this.dataSource.data;
    data.push(this.userData);
    var benefirRequest = {
      amount: this.userData.amount.toString(),
      currency: this.userData.currency,
      company: this.userData.project,
      month: this.month,
      subject: this.userData.subject,
    };
    this.itsService.createBenefit(this.userData.name.id, benefirRequest)
      .subscribe({
        next: benefits => {
          console.log('Monthly benefits:', benefits);
        },
        error: error => {
          console.error('Error fetching monthly benefits:', error);
        }
      });
    this.dataSource.data = data;
    this.userData = <UserData>{};
  }

  getBenefits() {
    console.log('Fetching monthly benefits for:', this.month);
    var data: UserData[] = [];
    this.itsService.getMonthlyBenefits(this.month)
      .subscribe({
        next: resp => {
          (resp.employees as MonthlyEmployeeBenefit[]).forEach(employee => {
            if (employee.benefits) {
                (employee.benefits as Benefit[]).forEach(b => {
                  console.log(b, employee.employee.first_name + ' ' + employee.employee.last_name);
                  data.push({
                    name: {name: employee.employee.first_name + ' ' + employee.employee.last_name, id: employee.employee.id},
                    project: b.company,
                    subject: b.subject,
                    amount: parseInt(b.amount),
                    currency: b.currency
                  });
              });
            }
          });
          this.dataSource = new MatTableDataSource(data);
        }, 
        error: error => {
          console.error('Error fetching monthly benefits:', error);
        }
      });
  }
}
