<!-- form.component.html -->
<form (ngSubmit)="onSubmit()" #userDataForm="ngForm">
  <mat-form-field class="example-full-width">
    <input #input
           type="text"
           placeholder="Name"
           matInput
           [matAutocomplete]="auto"
           (input)="filter()"
           (focus)="filter()"
           name="name"
           [(ngModel)]="userData.name" required>
    <mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredOptions" [value]="option">{{option.name}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Project" name="project" [(ngModel)]="userData.project" required>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Subject" name="subject" [(ngModel)]="userData.subject" required>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Amount" type="number" name="amount" [(ngModel)]="userData.amount" required>
  </mat-form-field>

  <mat-form-field>
    <mat-select placeholder="Currency" name="currency" [(ngModel)]="userData.currency" required>
      <mat-option *ngFor="let currency of currencies" [value]="currency">{{currency}}</mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button type="submit" [disabled]="userDataForm.invalid">Submit</button>
</form>

<!-- table.component.html -->
<table mat-table #table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.name.name}} </mat-cell>
  </ng-container>

  <!-- Project Column -->
  <ng-container matColumnDef="project">
    <mat-header-cell *matHeaderCellDef> Project </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.project}} </mat-cell>
  </ng-container>

    <!-- Subject Column -->
    <ng-container matColumnDef="subject">
      <mat-header-cell *matHeaderCellDef> Subject </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.subject}} </mat-cell>
    </ng-container>

      <!-- Amount Column -->
  <ng-container matColumnDef="amount">
    <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.amount}} </mat-cell>
  </ng-container>

  <!-- Currency Column -->
  <ng-container matColumnDef="currency">
    <mat-header-cell *matHeaderCellDef> Currency </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.currency}} </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</table>

<mat-paginator [pageSizeOptions]="[ 10, 15, 25, 100]"></mat-paginator>